import logo from './logo.svg';
import './App.css';

// firebase deploy --only hosting:breakroomwebsite

function App() {
  return (
    <p>Coming Soon</p>
  );
}

export default App;
